<template>
	<div class="container">
		<div class="banner wow slideInDown" style="visibility: visible;animation-duration: 1s;">
			<img src="../../assets/img/banner7.png" />
		</div>
		<div class="handpick">
			<div class="content">
				<div class="page-title wow fadeInDown" style="visibility: visible;">
					<img src="../../assets/img/title-handpick.png">
				</div>
				<div class="handpick-item">
					<div class="item wow fadeInDown" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/hand1.png" style="width: 300px;">
					</div>
					<div class="item wow fadeInUp" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/hand2.png" style="width: 300px;">
					</div>
					<div class="item wow fadeInDown" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/hand3.png" style="width: 300px;">
					</div>
					<div class="item wow fadeInUp" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/hand4.png" style="width: 300px;">
					</div>
				</div>
			</div>
		</div>
		<div class="function">
			<div class="content">
				<div class="page-title wow fadeInDown" style="visibility: visible;">
					<img src="../../assets/img/title-function.png">
				</div>
				<div class="function-item">
					<div class="item wow fadeInLeft" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/function1.png" style="width: 100px;">
						<div class="item-txt">
							<p class="txt1">海量精选图片视频库</p>
							<p class="txt2">精选案例</p>
						</div>
					</div>
					<div class="item wow fadeInDown" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/function2.png" style="width: 100px;">
						<div class="item-txt">
							<p class="txt1">数万用户共享发现库</p>
							<p class="txt2">案例共享</p>
						</div>
					</div>
					<div class="item wow fadeInUp" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/function3.png" style="width: 100px;">
						<div class="item-txt">
							<p class="txt1">团队会员成员素材免费下</p>
							<p class="txt2">会员专属素材</p>
						</div>
					</div>
					<div class="item wow fadeInRight" style="visibility: visible;animation-duration: 1s;">
						<img src="../../assets/img/function4.png" style="width: 100px;">
						<div class="item-txt">
							<p class="txt1">精选营销话术课程</p>
							<p class="txt2">一美课堂</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="advantage">
			<div class="content">
				<div class="page-title wow fadeInDown" style="visibility: visible;">
					<img src="../../assets/img/title-software.png">
				</div>
				<div class="advantage-item">
					<div class="item">
						<img class="item-img1 wow fadeInUp" style="visibility: visible;animation-duration: 1s"
							src="../../assets/img/soft01.png">
						<img class="item-img2 wow fadeInUp" style="visibility: visible;animation-duration: 1s"
							data-wow-delay="0.5s" src="../../assets/img/soft03.png">
					</div>
					<div class="item wow fadeInDown" style="animation-duration: 1s">
						<img src="../../assets/img/soft.png" style="width: 352px;">
					</div>
					<div class="item">
						<img class="item-img1 wow fadeInUp" style="visibility: visible;animation-duration: 1s"
							src="../../assets/img/soft02.png">
						<img class="item-img2 wow fadeInUp" style="visibility: visible;animation-duration: 1s"
							data-wow-delay="0.5s" src="../../assets/img/soft04.png">
					</div>
				</div>
			</div>
		</div>
		<div class="ymzs">
			<div class="content">
				<img class="wow fadeInLeft" src="../../assets/img/ymzs01.png" style="visibility: visible;width: 334px;">
				<img class="wow fadeInRight" src="../../assets/img/ymzs02.png" style="visibility: visible;width: 520px;">
			</div>
		</div>
		<div class="pattern">
			<div class="content">
				<div class="page-title wow fadeInDown">
					<img src="../../assets/img/title-acn.png" >
				</div>
				<div class="pattern-item">
					<div class="item wow fadeInLeft" style="visibility: visible;">
						<img src="../../assets/img/pattern01.png" style="width: 120px;">
						<div class="item-txt">
							<p class="txt1">将公域流量转化到私域</p>
							<p class="txt2">线上引流</p>
						</div>
					</div>
					<div class="item wow fadeInDown" style="visibility: visible;">
						<img src="../../assets/img/pattern02.png" style="width: 120px;">
						<div class="item-txt">
							<p class="txt1">帮助求美者定制美学方案</p>
							<p class="txt2">咨询服务</p>
						</div>
					</div>
					<div class="item wow fadeInUp" style="visibility: visible;">
						<img src="../../assets/img/pattern03.png" style="width: 120px;">
						<div class="item-txt">
							<p class="txt1">顾问式陪同服务</p>
							<p class="txt2">地接服务</p>
						</div>
					</div>
					<div class="item wow fadeInRight" style="visibility: visible;">
						<img src="../../assets/img/pattern04.png" style="width: 120px;">
						<div class="item-txt">
							<p class="txt1">协助用户向医院提出维权要求</p>
							<p class="txt2">术后维护</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: 'product',
		components: {},
		props: {},
		data() {
			return {}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true
			})
			wow.init()
		},
		methods: {}
	}
</script>

<style lang="less" scoped>
	@import './product.less';
</style>
