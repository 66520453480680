<template>
	<div class="ymkj-wrap">
		<el-header class="header">
			<div class="navbar">
				<div class="logo">
					<img src="../assets/img/logo.png" alt="logo">
				</div>
				<el-menu class="menu" :default-active="this.$route.path" active-text-color="#3D7EFF" mode="horizontal"
					router>
					<el-menu-item v-for="item in menuList" :index="'/' + item.path" :key="item.mid">
						<span slot="title">{{ item.menu }}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</el-header>
		<div class="main">
			<transition enter-active-class="animate__animated animate__fadeIn">
				<keep-alive>
					<router-view :key="key"></router-view>
				</keep-alive>
			</transition>
		</div>
		<div class="footer">
			<p class="company-name">安徽一美科技有限公司</p>
			<div class="police">
				<!-- <img class="police-icon" src="../assets/img/icon-police.png"> -->
				<p class="police-txt"> 皖网文（2019）6047-305号 <span @click="handleCIP">皖ICP备15026816号</span></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'navbar',
		components: {},
		props: {},
		data() {
			return {
				activePath: '',
				// 导航菜单
				menuList: [{
					menu: '首页',
					path: 'homepage',
					mid: 1
				}, {
					menu: 'ACN模式',
					path: 'pattern',
					mid: 2
				}, {
					menu: '产品介绍',
					path: 'product',
					mid: 3
				}, {
					menu: '常见问题',
					path: 'question',
					mid: 4
				}, {
					menu: '关于我们',
					path: 'aboutpage',
					mid: 5
				}]
			}
		},
		computed: {
			key() {
				return this.$route.path
			}
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			handleCIP() {
				window.open('https://beian.miit.gov.cn/', '_blank')
			}

		}
	}
</script>

<style lang="less" scoped>
	.ymkj-wrap {
		width: 100vw;
		height: 100%;
	}

	.el-header {
		width: 100vw;
		height: 80px !important;
		line-height: 80px;
		background: #fff;
		box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.1);
		z-index: 99999;
		position: fixed;
		top: 0;
		
	}

	.navbar {
		width: 1200px;
		height: 80px;
		margin: 0 auto;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.logo {
		width: 182px;
		height: 80px;
		display: flex;
		align-items: center;
	}

	.logo img {
		width: 182px;
		height: auto;
	}

	.menu {
		width: 618px;
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	/deep/.el-menu-item {
		font-size: 16px;
		padding: 0 0;
	}

	/deep/.el-menu--horizontal>.el-menu-item {
		height: 80px;
		line-height: 80px;
	}

	/deep/.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none;
		font-weight: bold;
		color: #3D7EFF;
	}

	/deep/.el-menu--horizontal>.el-menu-item.is-active::after {
		content: "";
		height: 3px;
		background: #3D7EFF;
		box-shadow: 0px -2px 4px 0px rgba(61, 126, 255, 0.37);
		border-radius: 2px;
		position: absolute;
		top: 77px;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.main {
		width: 100%;
		margin-top: 80px;
	}

	.footer {
		width: 100%;
		height: 130px;
		background: rgba(0, 0, 0, 0.8);
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.company-name {
		margin-bottom: 8px;
		font-size: 16px;
		color: #FFFFFF;
	}

	.police-icon {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.police {
		display: flex;
		align-items: center;
	}

	.police-txt {
		font-size: 16px;
		color: #FFFFFF;

		span {
			margin-left: 10px;
		}
	}
</style>
