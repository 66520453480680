<template>
	<div class="pattern">
		<div class="header">
			<img src="../../assets/img/banner6.png" alt="">
		</div>

		<div class="content">
			<p class="acn-desc wow fadeInDown">
				一美科技将传统的医美服务链条细化，不同经纪人之间以不同角色共同参与到一笔交易中，成交后按照各角色在各环节的贡献率进行佣金分成，从而提升产业链条的透明度；通过对经纪人的整合以及专业化培训，消费者可以获得定制化、陪伴式的高质量服务。
			</p>
		</div>
		
		<div class="content">
			<div class="acn-item wow slideInLeft">
				<div class="acn-item-left">
					<img src="../../assets/img/acn01.png" style="width: 390px;">
				</div>
				<div class="acn-item-right">
					<p class="number">01</p>
					<p class="title">线上引流</p>
					<p class="desc">批量打造KOC和KOL，利用短视频和直播来吸引流量，将公域流量转化到私域</p>
				</div>
			</div>
			
			<div class="acn-item wow slideInRight">
				<div class="acn-item-right">
					<p class="number">02</p>
					<p class="title">咨询服务</p>
					<p class="desc">给求美者提供专业的医美咨询服务，帮助求美者定制最适合自己的美学方案。</p>
				</div>
				<div class="acn-item-left">
					<img src="../../assets/img/acn02.png" style="width: 390px;">
				</div>
			</div>
			
			<div class="acn-item wow slideInLeft">
				<div class="acn-item-left">
					<img src="../../assets/img/acn03.png" style="width: 390px;">
				</div>
				<div class="acn-item-right">
					<p class="number">03</p>
					<p class="title">地接服务</p>
					<p class="desc">提供专车接送服务和顾问式陪同服务，让求美者省心省力，节省决策成本。</p>
				</div>
			</div>
			
			<div class="acn-item wow slideInRight">
				<div class="acn-item-right">
					<p class="number">04</p>
					<p class="title">术后维护</p>
					<p class="desc">术后定期回访，跟进手术恢复全过程，协助用户向医院提出修复或维权要求。</p>
				</div>
				<div class="acn-item-left">
					<img src="../../assets/img/acn04.png" style="width: 390px;">
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: 'ACNpattern',
		components: {},
		props: {},
		data() {
			return {}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true
			})
			wow.init()
		},
		methods: {}
	}
</script>

<style lang="less" scoped>
	@import './pattern.less';
</style>
