<template>
	<div id="app">
		<!--  路由出口 -->
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style lang="less">

</style>
