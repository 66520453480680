import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/navbar.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/homepage',
		component: Layout,
		children: [{
			path: 'homepage',
			name: 'homepage',
			component: () => import('@/views/homepage/home'),
			meta: {
				affix: true,
				title: '首页',
				icon: '',
				activePath: '/aboutpage'
			}
		}]
	},
	{
		path: '/',
		redirect: '/pattern',
		component: Layout,
		children: [{
			path: 'pattern',
			name: 'pattern',
			component: () => import('@/views/pattern/pattern'),
			meta: {
				affix: true,
				title: 'ACN模式',
				icon: ''
			}
		}]
	},
	{
		path: '/',
		redirect: '/product',
		component: Layout,
		children: [{
			path: 'product',
			name: 'product',
			component: () => import('@/views/product/product'),
			meta: {
				affix: true,
				title: '产品介绍',
				icon: ''
			}
		}]
	},
	{
		path: '/',
		redirect: '/question',
		component: Layout,
		children: [{
			path: 'question',
			name: 'question',
			component: () => import('@/views/question/question'),
			meta: {
				affix: true,
				title: '常见问题',
				icon: ''
			}
		}]
	},
	{
		path: '/',
		redirect: '/aboutpage',
		component: Layout,
		children: [{
			path: 'aboutpage',
			name: 'aboutpage',
			component: () => import('@/views/aboutpage/about'),
			meta: {
				affix: true,
				title: '关于我们',
				icon: ''
			}
		}]
	}
]

const router = new VueRouter({
	routes
})

export default router
