import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 加载 swiper 插件
import swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

// // 加载 normalize.css (浏览器默认的样式重置)
// import 'normalize.css/normalize.css'

// 加载全局样式文件
import './styles/styles.less'
import './styles/reset.less'

// 加载 animated 动画
import animate from 'animate.css'

// 加载 element 组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'

Vue.use(animate)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
