<template>
	<div class="container">
		<div class="header">
			<img v-show="tabCurrent==1" src="../../assets/img/banner4.png" alt="banner">
			<img v-show="tabCurrent==2" src="../../assets/img/banner5.png" alt="banner">
		</div>
		<div class="section-tab">
			<div class="tab-item" :class="item.tid==tabCurrent?'tab-item-active':''" v-for="item in tabList"
				:key="item.tid" @click="handleTabNav(item.tid)">{{item.tab}}</div>
		</div>
		<div class="section-content">
			<!-- 公司简介 -->
			<div class="content" v-show="tabCurrent==1">
				<div class="compony">
					<div class="compony-intro">
						<div class="compony-intro-title wow fadeInUp">ONE BEAUTY</div>
						<div class="compony-intro-text">
							<p class="wow fadeInUp">
								安徽一美科技有限公司（以下简称一美科技）成立于2014年，以“赋能百万医美经纪人”为使命，致力于打造国内领先的互联网医美平台。一美科技提出“医美经纪人”概念，首推医美行业ACN模式，聚集医院资源，掌握行业优质资源，并通过平台赋能医美经纪，为消费者提供全天候地接顾问式服务。
							</p>
							<p class="wow fadeInUp">
								1美平台自建售后及落地体系，通过分割、细化医美服务链条，实现更为合理的收益分配规则，进而向终端消费者提供最为合理、透明的服务价格体系。目前，公司业务覆盖全国，覆盖34个省，市，自治区，直辖市更触及海外等，与全国2000多家优质整形医院，12000名医生达成合作。
							</p>
						</div>
					</div>
					<div class="compony-logo wow fadeInUp">
						<img src="../../assets/img/YIMEIONE.png" alt="" style="width: 432px;">
					</div>
					<img class="compony-beauty" src="../../assets/img/BEAUTY.png" style="width: 540px;" />
				</div>
				<div class="compony planning wow fadeInUp">
					<div class="plan-item">
						<div class="plan-item-info">
							<img src="../../assets/img/p1.png" />
							<p>愿景</p>
						</div>
						<div class="plan-item-desc">成为国际一流的医美经纪平台</div>
					</div>
					<div class="plan-item">
						<div class="plan-item-info">
							<img src="../../assets/img/p2.png" />
							<p>使命</p>
						</div>
						<div class="plan-item-desc">赋能百万医美经纪人</div>
					</div>
					<div class="plan-item">
						<div class="plan-item-info">
							<img src="../../assets/img/p3.png" />
							<p>价值观</p>
						</div>
						<div class="plan-item-desc">客户第一  员工第二  股东第三</div>
					</div>
				</div>
				<div class="environment">
					<div class="environ-intro">
						<div class="environ-title wow fadeInUp">
							<img src="../../assets/img/title-env.png" />
						</div>
						<div class="environ-item">
							<div class="environ-left">
								<img class="wow fadeInUp" src="../../assets/img/env1.png" style="width: 618px;" />
								<div class="environ-left-img">
									<img class="wow fadeInUp" src="../../assets/img/env2.png" style="width: 301px;" />
									<img class="wow fadeInUp" src="../../assets/img/env5.png" style="width: 301px;" />
								</div>
							</div>
							<div class="environ-right">
								<div class="environ-item-img">
									<img class="wow fadeInUp" src="../../assets/img/env3.png" style="width: 370px;" />
								</div>
								<div class="environ-item-img" style="margin-top: 16px;">
									<img class="wow fadeInUp" src="../../assets/img/env4.png" style="width: 370px;" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="contact" id="concat">
					<div class="environ-title">
						<img src="../../assets/img/title-concat.png" />
					</div>
					<section class="contact-hhr">
						<div class="contact-hhr-image wow fadeInUp">
							<img src="../../assets/img/add.png" alt="">
						</div>
						<div class="contact-hhr-card wow fadeInUp">
							<img class="icon" src="../../assets/img/about-red-icon.png" alt="">
							<div class="vs-info">
								<div class="vs-info-title">联系我们</div>
								<div class="vs-info-text">
									<p>公司地址：合肥市四里河路万科中心2007</p>
									<p>邮编：230001</p>
									<p>联系电话：(0551) 6788 8301</p>
									<p>媒体联系：541917085@qq.com</p>
								</div>
							</div>
							<div class="vs-qrcode wow fadeInUp">
								<img src="../../assets/img/wechat.png" alt="">
							</div>
						</div>
					</section>
				</div>
			</div>

			<!-- 高管团队 -->
			<div class="content" v-show="tabCurrent==2">
				<div class="manage wow slideInLeft">
					<div class="manage-left">
						<img class="manage1" src="../../assets/img/manage1.png" style="width: 612px;" />
					</div>
					<div class="manage-right">
						<p class="p1">熊伟</p>
						<p class="p2">董事长</p>
						<p class="p3">深耕医美行业7年 首提医美ACN模式。<br /> 2014年创立一美科技，现任一美科技董事长。<br /> 负责公司整体战略及运营工作。 </p>
					</div>
				</div>
				<div class="manage wow slideInRight">
					<div class="manage-right manage-right2" style="background: #F0F3F7;">
						<p class="p1">黄闯</p>
						<p class="p2">首席执行官</p>
						<p class="p3">一美科技联合创始人，<br /> 现任一美科技首席执行官，<br /> 负责公司产品管理和日常运营工作。</p>
					</div>
					<div class="manage-left manage-left2">
						<img class="manage1" src="../../assets/img/manage2.png" style="width: 612px;" />
					</div>
				</div>
				<div class="manage wow slideInLeft">
					<div class="manage-left" style="margin-top: 74px;">
						<img class="manage1" src="../../assets/img/manage3.png" style="width: 612px;" />
					</div>
					<div class="manage-right">
						<p class="p1">陈寒晓</p>
						<p class="p2">副总裁兼首席人才官</p>
						<p class="p3">
							毕业于合肥工业大学 <br /> 曾就职于人工智能领军企业科大讯飞。<br />
							负责公司互联网平台建设，增强公司核心竞争力；<br />组织发展及人才建设，提升一美人才水平，塑造企业文化。
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: 'About',
		components: {},
		props: {},
		data() {
			return {
				tabCurrent: 1,
				tabList: [{
						tab: '公司简介',
						tid: '1'
					},
					{
						tab: '高管团队',
						tid: '2'
					}
				]
			}
		},
		computed: {},
		watch: {},
		created() {
			// 创建时执行跳转锚点位置
			// this.$nextTick(() => {
			// 	this.getlocal()
			// })
		},
		mounted() {
			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true
			})
			wow.init()
			this.$nextTick(function() {
				window.addEventListener('scroll', this.handleScroll)
			})
		},
		methods: {
			// tab切换
			handleTabNav(tid) {
				this.tabCurrent = tid
			}
			// getlocal() {
			// 	// 找到锚点id
			// 	const selectId = localStorage.getItem('concat')
			// 	const toElement = document.getElementById(selectId)
			// 	// 如果对应id存在，就跳转
			// 	if (selectId) {
			// 		toElement.scrollIntoView(true)
			// 	}
			// }

		}
	}
</script>

<style lang="less" scoped>
	@import './about.less';

	.content1 {}
</style>
