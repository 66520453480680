<template>
	<div class="container">
		<!-- banner图 -->
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<img class="animated" src="../../assets/img/banner1.png" />
				</div>
				<div class="swiper-slide">
					<img class="animated" src="../../assets/img/banner2.png" />
				</div>
				<div class="swiper-slide">
					<img class="animated" src="../../assets/img/banner3.png" />
				</div>
			</div>
			<div class="concat-btn" @click="goto('/aboutpage', 'concat')"></div>
			<div class="swiper-pagination"></div>
		</div>
		<!-- 核心优势 -->
		<div class="row youshi-bg">
			<div class="content">
				<div class="items">
					<div class="item-title">
						<img src="../../assets/img/title-advantage.png">
					</div>
					<div class="item-inner superiority">
						<div data-wow-duration=".7s" class="ty-box wow slideInLeft"
							style="visibility: visible; animation-duration: 0.7s;">
							<img src="../../assets/img/icon-youshi-1.png">
							<h1>7年医美专业经验</h1>
							<p>2000+优质医美机构，12000+执业医师，业务覆盖34个省市更触及海外</p>
						</div>
						<div data-wow-duration=".7s" class="ty-box wow slideInRight"
							style="visibility: visible; animation-duration: 0.7s;">
							<img src="../../assets/img/icon-youshi-2.png">
							<h1>自营顶尖经纪人团队</h1>
							<p>自主培养专业顾问，超百人团队，为求美者提供全天候、专业、及时的医美咨询服务。</p>
						</div>
						<div data-wow-duration=".7s" class="ty-box wow slideInLeft"
							style="visibility: visible; animation-duration: 0.7s;">
							<img src="../../assets/img/icon-youshi-3.png">
							<h1>全周期服务</h1>
							<p>陪伴式服务，线上专业顾问+豪华地接团队，提供术前、术中、术后的全周期、有保障的医美服务。</p>
						</div>
						<div data-wow-duration=".7s" class="ty-box wow slideInRight"
							style="visibility: visible; animation-duration: 0.7s;">
							<img src="../../assets/img/icon-youshi-4.png">
							<h1>后台技术保障</h1>
							<p>平台自主研发，定制化服务医院/医生端、顾问端，以及消费者端，提供稳定安全的技术保障。</p>
						</div>
					</div>
					<!-- <button class="btn">查看更多</button> -->
				</div>
			</div>
		</div>
		<!-- 医美ACN模式 -->
		<div class="row programme-bg">
			<div class="content">
				<div class="items" style="margin-top: 104px;">
					<div class="item-title">
						<img src="../../assets/img/title-acn.png">
					</div>
					<div class="item-inner programme">
						<div data-wow-duration="1s" class="programme_item wow fadeInLeft"
							style="visibility: visible; animation-duration: 1s;">
							<img src="../../assets/img/icon-acn-1.png">
							<div class="subhead">资源汇聚 </div>
							<div class="describe">
								培养医美经纪人团队，打造医美 产业最庞大的经纪人体系；前端 成立医美MCN机构，批量打造KO C和KOL，将公域流量引至平台；
								独家签约并运用互联网手段打造 医生IP，掌握优质的医生资源。
							</div>
						</div>
						<div data-wow-duration="1s" data-wow-delay=".4s" class="programme_item wow fadeInUp"
							style="visibility: visible; animation-duration: 1s; animation-delay: 0.4s;">
							<img src="../../assets/img/icon-acn-2.png">
							<div class="subhead">赋能产业链</div>
							<div class="describe">通过连接医生、经纪人、消费者三个关键性群体，撮合交易，提供诚信保障的医美服务</div>
						</div>
						<div data-wow-duration="1s" data-wow-delay=".8s" class="programme_item wow fadeInDown">
							<img src="../../assets/img/icon-acn-3.png">
							<div class="subhead">服务链条细化</div>
							<div class="describe">
								不同经纪人之间以不同角色共同参与到一笔交易中，按照各角色在各环节的贡献率进行佣金分成，从而提升产业链条的透明度
							</div>
						</div>
						<div data-wow-duration="1s" data-wow-delay="1.2s" class="programme_item wow fadeInUp">
							<img src="../../assets/img/icon-acn-4.png">
							<div class="subhead">高端私人服务</div>
							<div class="describe">帮助消费者定制最适合的医美方 案，提供手术陪同、专车接送等定 制化服务</div>
						</div>
					</div>
					<router-link to="/pattern"> <button class="btn">查看更多</button></router-link>
				</div>
			</div>
		</div>
		<!-- 产品介绍 -->
		<div class="row product-bg">
			<div class="content" style="margin-bottom: 54px;">
				<div class="item-title" style="padding-top: 54px;">
					<img src="../../assets/img/title-product.png">
				</div>
				<div class="product-item">
					<div data-wow-duration=".7s" class="product-item-left wow slideInLeft"
						style="visibility: visible; animation-duration: 0.7s;">
						<img src="../../assets/img/logo-ymzs.png" style="width: 130px;" />
						<div class="item-left-txt" style="margin-left: 20px;">
							<p>海量优质案例，丰富聊天话术。</p>
							<p>私域转化好助手，全力赋能医美营销。</p>
						</div>
						<router-link to="/product">
							<div class="btn">查看更多</div>
						</router-link>
					</div>
					<div data-wow-duration=".7s" class="product-item-right wow slideInRight"
						style="visibility: visible; animation-duration: 0.7s;">
						<img class="item-right-img" src="../../assets/img/product2.png" />
					</div>
				</div>
			</div>
		</div>
		<!-- 合作达人 -->
		<div class="row case-bg">
			<div class="content">
				<div class="items">
					<div class="item-title">
						<img src="../../assets/img/title-expert.png">
					</div>
					<div class="item-inner">
						<div class="case wow animated" style="visibility: visible;">
							<div class="title">木子</div>
							<div class="desc">
								<p>from：抖音</p>
								<p>粉丝： 52.5W</p>
							</div>
							<div class="tag">标签：<span>时尚</span><span>美妆</span><span>护肤</span></div>
							<div data-wow-duration="1.5s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1.5s;">
								<img src="../../assets/img/expert1.png">
							</div>
						</div>
						<div class="case wow animated" style="visibility: visible;">
							<div class="title">阿音Hwy</div>
							<div class="desc">
								<p>from： 快手</p>
								<p>粉丝： 45.8W</p>
							</div>
							<div class="tag">标签：<span>时尚</span> <span>美妆</span> <span>护肤</span></div>
							<div data-wow-duration="1.5s" data-wow-delay=".2s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1s; animation-delay: 1.5s;">
								<img src="../../assets/img/expert2.png">
							</div>
						</div>
						<div class="case wow animated" data-wow-duration="1.2s"
							style="visibility: visible; animation-duration: 1.2s;">
							<div class="title">程不乘_</div>
							<div class="desc">
								<p>from：抖音</p>
								<p>粉丝： 178.6W</p>
							</div>
							<div class="tag">标签：<span>时尚</span><span>美妆</span><span>护肤</span></div>
							<div data-wow-duration="1.5s" data-wow-delay=".4s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1s; animation-delay: 0.4s;">
								<img src="../../assets/img/expert3.png">
							</div>
						</div>
						<div class="case wow animated" data-wow-duration="1.2s"
							style="visibility: visible; animation-duration: 1.2s;">
							<div class="title">小13</div>
							<div class="desc">
								<p>from：抖音</p>
								<p>粉丝： 16.9W</p>
							</div>
							<div class="tag">标签：<span>时尚</span><span>美妆</span><span>护肤</span></div>
							<div data-wow-duration="1.5s" data-wow-delay=".6s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1s; animation-delay: 0.6s;">
								<img src="../../assets/img/expert4.png">
							</div>
						</div>
						<div class="case wow animated" data-wow-duration="1.2s"
							style="visibility: visible; animation-duration: 1.2s;">
							<div class="title">小猫里奈</div>
							<div class="desc">
								<p>from：抖音</p>
								<p>粉丝：9.9W</p>
							</div>
							<div class="tag">标签：<span>时尚</span><span>美妆</span><span>护肤</span></div>
							<div data-wow-duration="1.5s" data-wow-delay=".2s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s;">
								<img src="../../assets/img/expert5.png">
							</div>
						</div>
						<div class="case wow animated" data-wow-duration="1.2s"
							style="visibility: visible; animation-duration: 1.2s;">
							<div class="title">廖书艺</div>
							<div class="desc">
								<p>from：抖音</p>
								<p>粉丝： 57.3W</p>
							</div>
							<div class="tag">标签：<span>时尚</span><span>美妆</span><span>护肤</span></div>
							<div data-wow-duration="1.5s" data-wow-delay=".4s" class="picture wow flipInX"
								style="visibility: visible; animation-duration: 1s; animation-delay: 0.4s;">
								<img src="../../assets/img/expert6.png">
							</div>
						</div>
					</div>
				</div>
				<!-- <button class="btn">查看更多</button> -->
			</div>
		</div>
		<!-- 合作伙伴 -->
		<div class="row brand-bg">
			<div class="content">
				<div class="item-title">
					<img src="../../assets/img/title-partner.png">
				</div>
				<div class="brands">
					<div data-wow-duration=".7s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s;">
						<img class="brands-img" src="../../assets/img/partner1.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".1s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.1s;">
						<img class="brands-img" src="../../assets/img/partner2.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".2s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.2s;">
						<img class="brands-img" src="../../assets/img/partner3.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".3s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.3s;">
						<img class="brands-img" src="../../assets/img/partner4.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".4s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.4s;">
						<img class="brands-img" src="../../assets/img/partner5.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".5s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s;">
						<img class="brands-img" src="../../assets/img/partner6.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".6s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.6s;">
						<img class="brands-img" src="../../assets/img/partner7.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".7s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.7s;">
						<img class="brands-img" src="../../assets/img/partner8.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".8s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.8s;">
						<img class="brands-img" src="../../assets/img/partner9.png">
					</div>
					<div data-wow-duration=".7s" data-wow-delay=".9s" class="brands-item wow flipInX"
						style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.9s;">
						<img class="brands-img" src="../../assets/img/partner10.png">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: 'Home',
		components: {},
		props: {},
		data() {
			return {}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			this.swiper = new Swiper('.swiper-container', {
				autoplay: 3000,
				speed: 3000,
				autoplayDisableOnInteraction: false,
				loop: true,
				pagination: '.swiper-pagination',
				paginationClickable: true,
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				}
			})
			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true
			})
			wow.init()
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				// window.location.href = 'http://ymkj.1mei.vip/m'
				window.location.href = 'https://www.1mei.vip/m'
			} else {}
		},
		methods: {
			// 联系我们
			goto(page, concat) {
				const path = page
				const selectId = concat
				localStorage.setItem('concat', selectId)
				this.$router.push({
					path: path
				})
			}

		}
	}
</script>

<style lang="less" scoped>
	@import './home.less';

	/deep/.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		background: #3D7EFF;
		border: 3px solid #fff;
		transition: all 0.3s;
		opacity: 1;
	}

	/deep/.swiper-pagination-bullet-active {
		width: 14px;
		height: 30px;
		background: #fff;
		border: none;
		border-radius: 10px;
		box-shadow: 0px 3px 8px #3D7EFF;
	}

	/deep/.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 50px 15px 0;
	}
</style>
